<template>
  <div>
    <b-row>
      <dashboard-page-title
        :showSearch="false"
        :showFilter="false"
        :showMainActions="false"
      >
        العمليات المالية</dashboard-page-title
      >
    </b-row>
    <b-row>
      <b-col md="6">
        <balance-card
          :data="data.withdrawBalance"
          classes="user-wallet-small"
        />
      </b-col>
      <b-col md="6">
        <balance-card
          :data="data.withdrawBalance"
          classes="user-wallet-small"
        />
      </b-col>
    </b-row>

    <!-- table -->
    <main-table :fields="fields" list_url="doctors/withdrawals"></main-table>
  </div>
</template>

<script>
/*eslint-disable*/
import { core } from "@/config/pluginInit";
import balanceCard from "./balanceCard.vue";
import mainTable from "../../../../components/table/mainTable.vue";
export default {
  components: { balanceCard, mainTable },
  mounted() {
    core.index();
  },
  data() {
    return {
      data: {
        fields: [
          { label: "رقم الطلب", key: "id", class: "text-right" },
          { label: "المبلغ", key: "amount", class: "text-right" },
          { label: "الحالة", key: "status", class: "text-right" },
          { label: "تاريخ الإنشاء", key: "created_at", class: "text-right" },
        ],
        currentBalance: {
          balance: "",
          title: "رصيد محفظتي الحالي",
        },
        depositBalance: {
          balance: "",
          title: "إجمالي الإيداع",
        },
        withdrawBalance: {
          balance: "",
          title: "إجمالي السحب",
        },
        depositBalanceCount: {
          balance: "",
          title: "عدد عمليات الإيداع",
        },
        withdrawBalanceCount: {
          balance: "",
          title: "عدد عمليات السحب",
        },
      },
    };
  },
};
</script>
